import wiki from './cms/wiki.json';
import imgs from './cms/images_repo.json';
import {WorkInProgress} from "./workInProgress";
import {Avatar, Card, Carousel, Col, Row} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import Meta from "antd/es/card/Meta";

export function BooksBlacksunPart1() {
    return (
        <>
            <div className="row row-alt">
                <WorkInProgress />
            </div>
            <Row gutter={16}>
                <Col className="gutter-row" xs={24} xl={6}>
                    <Card
                        cover={
                            <Carousel autoplay autoplaySpeed={5000}>
                                <div>
                                    <a href={wiki["book_cydonian_girl"].link}>
                                        <img
                                            style={{width: "100%"}}
                                            alt={imgs["book_cydonian_girl_200_ru"].long_text}
                                            src={imgs["book_cydonian_girl_200_ru"].link}
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a href={wiki["book_cydonian_girl"].link}>
                                        <img
                                            style={{width: "100%"}}
                                            alt={imgs["book_cydonian_girl_560_ru"].long_text}
                                            src={imgs["earth_landscape_1_200"].link}
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a href={wiki["book_cydonian_girl"].link}>
                                        <img
                                            style={{width: "100%"}}
                                            alt={imgs["book_cydonian_girl_560_ru"].long_text}
                                            src={imgs["bs_char_de_levine_rosalie_2_jpg_500"].link}
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a href={wiki["book_cydonian_girl"].link}>
                                        <img
                                            style={{width: "100%"}}
                                            alt={imgs["book_cydonian_girl_560_ru"].long_text}
                                            src={imgs["bs_char_the_whip_1_jpg_500"].link}
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a href={wiki["book_cydonian_girl"].link}>
                                        <img
                                            style={{width: "100%"}}
                                            alt={imgs["book_cydonian_girl_560_ru"].long_text}
                                            src={imgs["bs_char_esora_eve_2_jpg_200"].link}
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a href={wiki["book_cydonian_girl"].link}>
                                        <img
                                            style={{width: "100%"}}
                                            alt={imgs["book_cydonian_girl_560_ru"].long_text}
                                            src={imgs["bs_char_de_karma_ivar_2_jpg_500"].link}
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a href={wiki["book_cydonian_girl"].link}>
                                        <img
                                            style={{width: "100%"}}
                                            alt={imgs["book_cydonian_girl_560_ru"].long_text}
                                            src={imgs["bs_char_can_beucan_gaereas_1_jpg_500"].link}
                                        />
                                    </a>
                                </div>
                                <div>
                                    <a href={wiki["book_cydonian_girl"].link}>
                                        <img
                                            style={{width: "100%"}}
                                            alt={imgs["book_cydonian_girl_560_ru"].long_text}
                                            src={imgs["bs_char_browdy_adam_1_jpg_500"].link}
                                        />
                                    </a>
                                </div>
                            </Carousel>
                        }
                        actions={[
                            <div
                                onClick={() => {
                                    window.location.assign('https://adele.sergey-krekhno.com/Черное солнце 1 - Кидонианка (A4).pdf')
                                }}
                            >
                                <DownloadOutlined/> PDF A4
                            </div>,
                            <div
                                onClick={() => {
                                    window.location.assign('https://adele.sergey-krekhno.com/Черное солнце 1 - Кидонианка (A5).pdf')
                                }}
                            >
                                <DownloadOutlined/> PDF A5
                            </div>,
                            <div
                                onClick={() => {
                                    window.location.assign('https://adele.sergey-krekhno.com/Черное солнце 1 - Кидонианка (A6).pdf')
                                }}
                            >
                                <DownloadOutlined/> PDF A6
                            </div>,
                        ]}
                    >
                        <Meta
                            avatar={<Avatar src={imgs["bs_char_de_levine_rosalie_1_jpg_200"].link}/>}
                            title="Кидонианка"
                            description="10.03.2021"
                        />
                    </Card>
                </Col>
                <Col className="gutter-row" xs={24} xl={6}>
                    <Card
                        cover={
                            <img
                                onClick={() => {
                                    window.location.assign(wiki["book_hyperbeacons"].link)
                                }}
                                style={{cursor: "pointer"}}
                                alt={imgs["book_hyperbeacons_200_ru"].long_text}
                                src={imgs["book_hyperbeacons_200_ru"].link}
                            />
                        }
                        actions={[
                            <DownloadOutlined key="setting"/>,
                            <DownloadOutlined key="edit"/>,
                            <DownloadOutlined key="ellipsis"/>,
                        ]}
                    >
                        <Meta
                            avatar={<Avatar src={imgs["bs_char_skarsgard_oliver_1_jpg_200"].link}/>}
                            title="Card title"
                            description="This is the description"
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
    // return (
    //     <>
    //         <div className="row row-alt">
    //             <WorkInProgress />
    //         </div>
    //         <div className="row row-alt">
    //             <div className="text-center col-lg-3 book-item">
    //                 <h5 className="ordinary"><a href={wiki["book_cydonian_girl"].link}>Кидонианка</a><br/>10.03.2021
    //                 </h5>
    //                 <a href={wiki["book_cydonian_girl"].link}>
    //                     <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin": "auto"}}>
    //                         <img style={{"maxWidth": "100%"}} alt="Обложка Кидонианки Сергея Крехно"
    //                              src={imgs["book_cydonian_girl_200_286_ru"].link}/>
    //                     </div>
    //                 </a>
    //             </div>
    //             <div className="text-center col-lg-3 book-item">
    //                 <h5 className="ordinary"><a href={wiki["book_hyperbeacons"].link}>Похитители маяков</a><br/>09.09.2021
    //                 </h5>
    //                 <a href={wiki["book_hyperbeacons"].link}>
    //                     <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin": "auto"}}>
    //                         <img style={{"maxWidth": "100%"}} alt="Обложка Похитителей маяков Сергея Крехно"
    //                              src={imgs["book_hyperbeacons_200_286_ru"].link}/>
    //                     </div>
    //                 </a>
    //             </div>
    //             <div className="text-center col-lg-3 book-item">
    //                 <h5 className="ordinary"><a href={wiki["book_cydonian_autumn"].link}>Кидонианская осень</a><br/>~2024
    //                 </h5>
    //                 <a href={wiki["book_cydonian_autumn"].link}>
    //                     <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin": "auto"}}>
    //                         <img style={{"maxWidth": "100%"}} alt="Обложка Кидонианской осени Сергея Крехно"
    //                              src={imgs["book_cydonian_autumn_200_286_ru"].link}/>
    //                     </div>
    //                 </a>
    //             </div>
    //             <div className="text-center col-lg-3 book-item">
    //                 <h5 className="ordinary"><a href={wiki["book_bonecollectors"].link}>Собиратели костей</a><br/>~2025
    //                 </h5>
    //                 <a href={wiki["book_bonecollectors"].link}>
    //                     <div className="img-thumbnail-v2" style={{"maxWidth": "210px", "margin": "auto"}}>
    //                         <img style={{"maxWidth": "100%"}} alt="Обложка Собирателей костей Сергея Крехно"
    //                              src={imgs["book_bonecollectors_200_286_ru"].link}/>
    //                     </div>
    //                 </a>
    //             </div>
    //         </div>
    //     </>
    // )
}